<template>
  <div class="payments animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" class="main-col">
      <div class="card-container">
        <div class="emp-card pt-4 pb-6">
          <v-row class="mt-2">
            <v-col class="text-center">
              <img src="@/assets/images/big.png" class="logo-class" alt="" />
            </v-col>
          </v-row>
          <v-row class="px-8 mt-10 mb-2">
            <v-col class="name-div" cols="12" sm="12" md="6" lg="6" xl="6">
              <h2 class="name-style mx-4">{{ userData.name }}</h2>
              <!--<hr width="80" class="hr-tag" />-->
              <h6 class="name-style mt-2 mx-4">{{ userData.role }}</h6>

              <div class="straight-line"></div>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <div
                class="d-flex"
                v-if="
                  (job == 'doctor' && userData.docCode) ||
                  (job == 'nurse' && userData.docCode)
                "
              >
                <div class="w-100 text-left">
                  <h5
                    style="font-size: 1.9rem !important"
                    class="name-style px-2 pt-2"
                  >
                    {{ userData.docCode }}
                  </h5>
                </div>
                <v-icon class="icon-class"
                  >mdi-card-account-details-star-outline</v-icon
                >
              </div>
              <!--<hr width="50" class="hr-tag" />-->
              <div class="d-flex my-2" v-if="userData.mobile">
                <div class="w-100 text-left">
                  <h5
                    style="font-size: 1.9rem !important"
                    class="name-style px-2 pt-2"
                  >
                    {{ userData.mobile }}
                  </h5>
                </div>
                <v-icon class="icon-class">mdi-cellphone-text</v-icon>
              </div>
              <div class="d-flex" v-if="userData.email">
                <div class="w-100 text-left">
                  <h5
                    style="font-size: 1.9rem !important"
                    class="name-style px-2 pt-2"
                  >
                    {{ userData.email }}
                  </h5>
                </div>
                <v-icon class="icon-class"
                  >mdi-email-open-multiple-outline</v-icon
                >
              </div>
            </v-col>
          </v-row>
          <div
            class="w-100 justify-content-center d-flex mt-3 px-4"
            v-if="userData.address"
          >
            <hr class="w-100 text-center mt-0" />
          </div>
          <v-row v-if="userData.address">
            <v-col class="mx-5">
              <div v-if="userData.address" class="d-flex pt-2">
                <v-icon class="icon-class"
                  >mdi-map-marker-radius-outline</v-icon
                >
                <div class="text-right">
                  <h6 class="name-style px-2 pt-2">{{ userData.address }}</h6>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
  </div>
</template>
<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
export default {
  props: ["userData", "job"],
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
<style lang="scss">
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .emp-card {
    width: 700px;
    height: auto;
    border-radius: 30px;
    background-color: #e6fdff;
    box-shadow: 0 10px 5px -10px;
    .name-style {
      color: #656a81;
    }
    .hr-tag {
      border-top: 2px double #656a81;
    }
    .straight-line {
      height: 100px;
      width: 3px;
      border-radius: 10px;
      background-color: rgba(37, 66, 145, 0.664);
      position: absolute;
    }
    .icon-class {
      color: rgba(37, 66, 145, 0.748);
    }
    .name-div {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .logo-class {
      max-width: 250px;
    }
  }
}
</style>
